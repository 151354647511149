import { render, staticRenderFns } from "./PaginationComponent.vue?vue&type=template&id=621d0dce"
import script from "./PaginationComponent.vue?vue&type=script&lang=js"
export * from "./PaginationComponent.vue?vue&type=script&lang=js"
import style0 from "./PaginationComponent.vue?vue&type=style&index=0&id=621d0dce&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/appdata/www/radzs_diego/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('621d0dce')) {
      api.createRecord('621d0dce', component.options)
    } else {
      api.reload('621d0dce', component.options)
    }
    module.hot.accept("./PaginationComponent.vue?vue&type=template&id=621d0dce", function () {
      api.rerender('621d0dce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/frontend/components/common/PaginationComponent.vue"
export default component.exports