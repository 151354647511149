<template>
  <div>
    <div
      v-if="!creatives.length"
      class="text-center mt-5"
    >
      <b-spinner variant="primary" />
      <p class="mt-2">
        Loading...
      </p>
    </div>

    <div v-else>
      <div
        v-for="(creative, index) in creatives"
        :key="index"
        class="creative-item mt-3 mb-2 border rounded p-3"
      >
        <h3>{{ creative.name }}</h3>
        <img
          :src="creative.url"
          alt="preview"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WalmartShowPreview',
  data() {
    return {
      formData: {
        creative_id: null,
        platform_id: null,
        company_id: null,
      },
      creatives: []
    }
  },
  mounted() {
    this.getParams();

    this.fetchPreviews();
  },
  methods: {
    getParams() {
      const urlParams = new URLSearchParams(window.location.search);
      this.formData.creative_id = urlParams.get('creative_id');
      this.formData.platform_id = urlParams.get('platform_id');
      this.formData.company_id = urlParams.get('company_id');
    },
    async fetchPreviews() {
      const { data } = await axios.post('/walmart/fetch-previews', this.formData);

      if (data.status === 'error') {
        toastr.options = {
          timeOut: 0,
          extendedTimeOut: 0
        };
        toastr.error(data.message);
        return;
      }

      if (data.status === 'failed') {
        window.location.reload();
        return;
      }

      data.forEach((creative) => {
        let newCreative = {};

        newCreative.name = creative.name;

        newCreative.url = this.generateUrl(creative.image);

        this.creatives.push(newCreative);
      })
    },
    generateUrl(image) {
      const byteCharacters = atob(image);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/jpeg' });

      const previewUrl = URL.createObjectURL(blob);

      return previewUrl;
    }
  },
}
</script>