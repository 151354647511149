import { listFonts } from "../services/helpers";
import API_MEDIA from '../services/api/media'
import FontFaceObserver from "fontfaceobserver";
import similarity from "../services/helpers/similarity-text";

const FONT_NAME_MATCHING_COEFFICIENT = 0.85;
export default class FontFamilyManager {

  _allFontsData = []
  fonts = []
  fontFamily = []
  fontList = {}
  _themeFonts = []

  _initFontFamily(fonts = []) {
    this.fontFamily = fonts.reduce((acc, font) => {
      if (!font.font_family) return acc

      const fontFamily = acc.find(fontFamily => fontFamily.id === font.family_id);

      if (!fontFamily) {
        font.font_family.fonts = [{...font, font_family: null}]
        acc.push(font.font_family)
      } else {
        fontFamily.fonts.push({...font, font_family: null})
      }

      return acc
    }, [])
  }

  _initFont(fonts = []) {
    this.fonts = fonts.filter(font => !font.font_family)
  }

  _initFontList() {
    this.fontList = {};

    this.fontFamily.forEach(fontFamily => {
      fontFamily.fonts.forEach(font => {
        if (!this.fontList[fontFamily.title]) this.fontList[fontFamily.title] = []
        this.fontList[fontFamily.title].push(font.index);
      })
    })

    this.fonts.forEach(font => {
      this.fontList[font.index] = font.name
    })
  }

  _initLoadFontFace(fonts = this._allFontsData) {
    const promises = []
    const fontList = listFonts()
    
    fonts.forEach((font) => {
      if (!fontList.includes(font.index)) return
      const fontObserver = new FontFaceObserver(font.index)
      promises.push(fontObserver.load(null, 30000).catch((e) => {
        console.log('loading font-family: ' + font.index, 'error: ' + e)
      }))
    })

    return Promise.all(promises);
  }

  findSimilarFontByIndex(fontName) {
    let info = null;
    let startCoefficient = FONT_NAME_MATCHING_COEFFICIENT;

    for (const font of this.getAllFontsData()) {
        
        if (fontName === font.index) {
            info = font;
            break;
        }

        let simularCoefficient = similarity(fontName, font.name);

        if (simularCoefficient > startCoefficient) {
            info = font;
            startCoefficient = simularCoefficient;
        }
    }

    return info;
  }

  findSimilarFontByIndexes(fontNames) {
    let info = null;

    for (const fontName of fontNames) {
      info = this.findSimilarFontByIndex(fontName);

      if (info) {
        return info;
      }
    }

    return info;
  }

  getAllFontsData() {
    return this._themeFonts.length ? this._themeFonts : this._allFontsData;
  }

  getFontById(fontId) {
    return this.getAllFontsData().find(font => font.id === fontId)
  }

  getFontByIndex(index) {
    return this.getAllFontsData().find(font => font.index === index)
  }

  getFirstFont() {
    return this.getAllFontsData()?.[0];
  }

  getFontsFamily(index) {
    return this.fontFamily.find(
      fontFamily => fontFamily.fonts.find(font => font.index === index)
    );
  }

  getFontList() {
    return this.fontList
  }

  getAllFonts() {
    return this.getAllFontsData().reduce((acc, font) => {
      acc[font.index] = font.name
      return acc
    }, {})
  }

  getFontName(index) {
    return this.fontList[index] || ''
  }

  setThemeFonts(fonts = []) {
    this._themeFonts = fonts.map(fontIndex => {
      return this.getAllFontsData().find(font => font.index === fontIndex)
    }).filter(Boolean)

    const fontsArray = this._themeFonts.length ? this._themeFonts : this._allFontsData

    this._initFontFamily(fontsArray)
    this._initFont(fontsArray)
    this._initFontList()
  }

  async loadData() {
    if (this._allFontsData.length) return

    if (!this.promiseLoadData) {
      this.promiseLoadData = API_MEDIA.getGenerateFonts()
    }

    const { data } = await this.promiseLoadData
    this._allFontsData = data
    this._initFontFamily(this._allFontsData)
    this._initFont(this._allFontsData)
    this._initFontList()
  }

  load(fonts) {
    return this._initLoadFontFace(fonts)
  }
}