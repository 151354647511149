import Template from "./template";
import { PARSED_FIELD_TYPES } from "./constants";
import {getInstanceByType} from "@/includes/parse_psd_data/psd-types/folder";


function initData(data) {
    return data.tree.map((template) => {
        return new Template(template);
    });
}


export default class PsdDataContainer {

    data;

    width;

    height;

    templates = [];

    entireImage = ''

    entireImageThumbnail = ''

    constructor(data) {
        this.data = data;
        this.width = data.width;
        this.height = data.height;
        this.templates = initData(data);
        this.entireImage = data.entireImage
        this.entireImageThumbnail = data.entireImageThumbnail
    }

    toJSON() {
        return this.data;
    }
}
