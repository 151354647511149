var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-row",
    {
      ref: "uploadImageRow",
      on: {
        drop: function ($event) {
          $event.preventDefault()
          return _vm.onDrop.apply(null, arguments)
        },
        dragstart: function ($event) {
          $event.preventDefault()
        },
        dragenter: function ($event) {
          $event.preventDefault()
          return _vm.onDragEnter.apply(null, arguments)
        },
        dragleave: function ($event) {
          $event.preventDefault()
          return _vm.onDragLeave.apply(null, arguments)
        },
        dragover: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c(
        "b-col",
        { attrs: { cols: "auto" } },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              attrs: {
                size: "sm",
                title: "Click or drag the image to this button to upload",
                variant: "primary",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onClick.apply(null, arguments)
                },
              },
            },
            [_c("b-icon-upload")],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.uploadedImages.length
        ? _vm._l(_vm.uploadedImages, function ({ key, src, name }) {
            return _c(
              "b-col",
              {
                key: key,
                attrs: { cols: "auto" },
                on: {
                  click: function ($event) {
                    return _vm.removeFileFromList(key)
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "preview-image-container",
                  style: { backgroundImage: "url('" + src + "')" },
                  attrs: { title: name },
                }),
              ]
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.files.size
        ? [
            _c(
              "b-col",
              { attrs: { cols: "auto" } },
              [
                _c(
                  "b-button",
                  {
                    attrs: { size: "sm", title: "Replace", variant: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.uploadImages("files")
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "w-6 h-6",
                      staticStyle: {
                        "max-width": "18px",
                        "max-height": "18px",
                      },
                      attrs: { src: "/img/icons/replace.svg" },
                    }),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { cols: "auto" } },
              [
                _c(
                  "b-button",
                  {
                    attrs: {
                      size: "sm",
                      title: "Cancel upload",
                      variant: "danger",
                    },
                    on: { click: _vm.resetUpload },
                  },
                  [_c("b-icon-x-lg")],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-col",
        {
          staticClass: "d-flex align-items-center",
          attrs: { cols: _vm.isShownUrlInput ? "10" : "auto" },
        },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              attrs: {
                size: "sm",
                title: "Upload from URL",
                variant: "primary",
              },
              on: {
                click: function ($event) {
                  _vm.isShownUrlInput = !_vm.isShownUrlInput
                },
              },
            },
            [_c("b-icon-link")],
            1
          ),
          _vm._v(" "),
          _vm.isShownUrlInput
            ? _c("b-input", {
                attrs: { size: "sm", type: "url" },
                model: {
                  value: _vm.imageUrl,
                  callback: function ($$v) {
                    _vm.imageUrl = $$v
                  },
                  expression: "imageUrl",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.imageUrl && _vm.isShownUrlInput
            ? _c(
                "b-button",
                {
                  attrs: { size: "sm", title: "Replace", variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.uploadImages("url")
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "w-6 h-6",
                    staticStyle: { "max-width": "18px", "max-height": "18px" },
                    attrs: { src: "/img/icons/replace.svg" },
                  }),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { staticClass: "mt-2", attrs: { cols: "12" } },
        [
          _c(
            "b-form-checkbox",
            {
              staticClass: "cursor-pointer",
              attrs: { inline: "" },
              model: {
                value: _vm.needRemoveBackground,
                callback: function ($$v) {
                  _vm.needRemoveBackground = $$v
                },
                expression: "needRemoveBackground",
              },
            },
            [_vm._v("\n      Remove background\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-form-checkbox",
            {
              staticClass: "cursor-pointer",
              attrs: { inline: "" },
              model: {
                value: _vm.needCropToImage,
                callback: function ($$v) {
                  _vm.needCropToImage = $$v
                },
                expression: "needCropToImage",
              },
            },
            [_vm._v("\n      Crop to image\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }