var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex mt-3 mb-2", class: _vm.className }, [
    _c(
      "ul",
      { staticClass: "pagination ml-auto mr-auto" },
      [
        _vm.pagination.current_page > 1
          ? _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: "javascript:void(0)",
                    "aria-label": "Previous",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.changePage(_vm.pagination.current_page - 1)
                    },
                  },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("<"),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.pagesNumber.length > 1
          ? _vm._l(_vm.pagesNumber, function (page) {
              return _c(
                "li",
                {
                  key: page,
                  class: { active: page == _vm.pagination.current_page },
                },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.changePage(page)
                        },
                      },
                    },
                    [_vm._v(_vm._s(page))]
                  ),
                ]
              )
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.pagination.current_page < _vm.pagination.last_page
          ? _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)", "aria-label": "Next" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.changePage(Number(_vm.pagination.current_page) + 1)
                    },
                  },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v(">"),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }