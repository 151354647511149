var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-grid-view",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.clearSelection.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.folderItems.length,
              expression: "folderItems.length",
            },
          ],
          staticClass: "c-grid-view__container folder-items pb-3",
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.clearSelection.apply(null, arguments)
            },
          },
        },
        _vm._l(_vm.folderItems, function (folderItem) {
          return _c("GridItem", {
            key: folderItem.id,
            attrs: {
              item: folderItem,
              "is-selected": _vm.isSelected(folderItem),
              "is-available-overlay": false,
              draggable: _vm.isDragAndDropAvailable,
            },
            on: {
              click: _vm.itemClickHandled,
              dblclick: _vm.dbClickHandler,
              dragstart: function ($event) {
                return _vm.onDragstart($event, folderItem)
              },
              drop: function ($event) {
                $event.stopPropagation()
                return _vm.onDrop($event, folderItem)
              },
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "c-grid-view__container other-items",
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.clearSelection.apply(null, arguments)
            },
          },
        },
        _vm._l(_vm.otherItems, function (otherItem) {
          return _c("GridItem", {
            key: otherItem.id,
            attrs: {
              item: otherItem,
              "is-selected": _vm.isSelected(otherItem),
              "is-available-overlay":
                _vm.isItemOverlayEnabled && !_vm.onlyOneItemSelection,
              draggable: _vm.isDragAndDropAvailable,
            },
            on: {
              click: _vm.itemClickHandled,
              dblclick: _vm.dbClickHandler,
              dragstart: function ($event) {
                return _vm.onDragstart($event, otherItem)
              },
              drop: function ($event) {
                $event.stopPropagation()
                return _vm.onDrop($event, otherItem)
              },
            },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }