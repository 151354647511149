var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onSearchKeyBlur,
          expression: "onSearchKeyBlur",
        },
      ],
      staticClass: "product-images-input",
    },
    [
      _c("b-form-input", {
        attrs: { id: "multipleValues", placeholder: "Product images" },
        on: { input: _vm.handleInputChange, focus: _vm.expandDropdown },
        model: {
          value: _vm.searchInputValue,
          callback: function ($$v) {
            _vm.searchInputValue = $$v
          },
          expression: "searchInputValue",
        },
      }),
      _vm._v(" "),
      _vm.searchInputValue
        ? _c(
            "b-button",
            {
              staticClass: "clear-product-input-btn",
              attrs: { variant: "light" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.show("modal-warning-product-image")
                },
              },
            },
            [
              _c("b-icon", {
                staticClass: "icon-clear-input",
                attrs: { icon: "x-circle" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dropdownIsExpanded
        ? _c("div", { attrs: { id: "file_ids_dropdown" } }, [
            _vm.showEmptyRequest()
              ? _c("div", { staticClass: "product-message" }, [
                  _vm._v("\n      Nothing was found\n    "),
                ])
              : _c("div", { staticClass: "image-grid-responsive" }, [
                  _c(
                    "div",
                    { staticClass: "grid" },
                    [
                      _vm.loading
                        ? _c("div", { staticClass: "product-message" }, [
                            _vm._v(
                              "\n          Loading images from database...\n        "
                            ),
                          ])
                        : _vm._l(_vm.pagination.items, function (item) {
                            return _c(
                              "div",
                              {
                                key: item.id,
                                staticClass: "product-grid-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit(
                                      "dropdown-item-click",
                                      item
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "product-grid-item-controls",
                                    class: {
                                      "is-check": _vm.getProductsIds.includes(
                                        item.id
                                      ),
                                    },
                                  },
                                  [
                                    _c("input", {
                                      staticClass: "select-check",
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: _vm.getProductsIds.includes(
                                          item.id
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("img", {
                                  attrs: {
                                    src: `/share?file=${item.thumbnail}`,
                                    loading: "lazy",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticStyle: { "word-break": "break-all" },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.name) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                    ],
                    2
                  ),
                ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "image-grid-pagination" },
              [
                _c("VuePagination", {
                  attrs: {
                    pagination: _vm.pagination,
                    "class-name": "folders-library-pagination",
                  },
                  on: {
                    "update:pagination": function ($event) {
                      _vm.pagination = $event
                    },
                    paginate: function ($event) {
                      return _vm.search()
                    },
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-warning-product-image",
            "hide-backdrop": "",
            "content-class": "shadow",
            title: "",
          },
          on: { ok: _vm.clearInputValue },
        },
        [
          _c("p", { staticClass: "my-2" }, [
            _vm._v(
              "\n      Your changes will be lost! Are you sure you want to clear the workspace?\n    "
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }