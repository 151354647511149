<template>
  <b-modal
    id="export-templates-modal"
    title="Export"
    centered
  >
    <div class="form-row">
      <div class="col-md-2">
        <label>Format</label>
      </div>
      <div class="col-md-10 form-group">
        <b-form-select
          v-model="selectedFormat"
          :options="formats"
          class="form-control"
        />
      </div>
    </div>
    <template v-if="selectedFormat === 'pptx'">
      <div class="form-row">
        <div class="col-md-2">
          <label>PPTX output type</label>
        </div>
        <div class="col-md-10 form-group">
          <b-form-select
            v-model="pptxIncludeFonts"
            :options="pptxOutputTypes"
            class="form-control"
          />
        </div>
      </div>
    </template>
    <template v-if="selectedFormat === 'gif' || selectedFormat === 'pdf'">
      <div class="form-row container-downloadable-groups">
        <div class="col-md-2">
          <label>Group</label>
        </div>
        <div class="col-md-10 form-group">
          <MultipleSelectJquery
            v-model="selectedGroupInstances"
            class="export-pdf-groups-dropdown"
            multiple
            :data="groupsInstances"
          />
        </div>
      </div>

      <div
        v-if="selectedFormat === 'pdf'"
        class="form-row"
      >
        <div class="col-md-2" />
        <div class="col-md-10 form-group">
          <label>
            <input
              v-model="createOneFilePerGroup"
              type="checkbox"
            >
            Create one file for each group
          </label>
        </div>
      </div>
      
      <div
        v-if="selectedFormat === 'pdf'"
        class="form-row"
      >
        <div class="col-md-2" />
        <div class="col-md-10 form-group">
          <label>
            <input
              v-model="createPageForEachTemplate"
              type="checkbox"
            >
            Create one page for each template in the group
          </label>
        </div>
      </div>

      <div
        v-if="selectedFormat === 'pdf' && selectedGroupInstances.length == 1"
        class="form-row"
      >
        <div class="col-md-2" />
        <div class="col-md-10 form-group">
          <label>
            <input
              v-model="getDirectLinktoFile"
              type="checkbox"
            >
            Get direct link to the file
          </label>
        </div>
      </div>

      <div 
        v-if="selectedFormat === 'pdf'"
        class="form-row -groups"
      >
        <div class="col-md-2">
          <label>Save to folder</label>
        </div>
        <div class="col-md-10 form-group">
          <SelectFolder
            :items="folders"
            @update:selectedFolder="handleSelection"
          />
        </div>
      </div>
      <div class="form-row container-downloadable-single-template">
        <div class="col-md-2">
          <label>Single Templates</label>
        </div>
        <div class="col-md-10 form-group">
          <MultipleSelectJquery
            v-model="selectedSingleInstances"
            multiple
            :data="singleInstances"
          />
        </div>
      </div>
    </template>

    <template v-else>
      <div class="form-row container-downloadable-templates">
        <div class="col-md-2">
          <label>Download</label>
        </div>
        <div class="col-md-10 form-group">
          <Multiselect
            v-model="selectedInstances"
            :options="instancesOption"
            track-by="instance_id"
            label="name"
            :loading="isTemplatesLoading"
            placeholder="Select instances..."
            :close-on-select="false"
            multiple
          />
        </div>
      </div>
      <div 
        v-if="selectedFormat === 'pptx'"
        class="form-row -groups"
      >
        <div class="col-md-2">
          <label>Save to folder</label>
        </div>
        <div class="col-md-10 form-group">
          <SelectFolder
            :items="folders"
            @update:selectedFolder="handleSelection"
          />
        </div>
      </div>
    </template>
    
    <div
      v-if="['psd', 'jpg_from_psd', 'psd_and_jpg'].includes(selectedFormat)"
      class="form-row"
    >
      <div class="col-md-2" />
      <div class="col-md-10 form-group">
        <label>
          <input
            v-model="downloadInBackground"
            type="checkbox"
          >
          Download in background
        </label>
      </div>
    </div>      

    <div
      v-if="['psd', 'jpg', 'png', 'jpg_from_psd'].includes(selectedFormat)"
      class="form-row container-platforms"
    >
      <div class="col-md-2">
        <label>DPI</label>
      </div>
      <div class="col-md-10 form-group">
        <b-form-select
          v-model.number="selectedDpi"
          class="form-control"
          @change="dpi = selectedDpi"
        >
          <option value="72">
            72
          </option>
          <option value="75">
            75
          </option>
          <option value="100">
            100
          </option>
          <option value="150">
            150
          </option>
          <option value="200">
            200
          </option>
          <option value="300">
            300
          </option>
          <option value="0">
            Custom
          </option>
        </b-form-select>
        <div class="my-2" />
        <b-form-input
          v-if="!selectedDpi"
          v-model.number="dpi"
          type="number"
        />
      </div>
    </div>
    <div
      v-show="['jpg', 'png'].includes(selectedFormat)"
      class="form-row"
    >
      <div class="col-md-2">
        <label>Size</label>
      </div>
      <div class="col-md-10 form-group">
        <b-form-select
          v-model.number="selectedSize"
          class="form-control"
        >
          <option :value="SIZE_OPTIONS_TYPE.KEEP_ORIGINAL">
            Keep original size
          </option>
          <option :value="SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_PERCENTAGE">
            Scale down by %
          </option>
          <option
            v-if="selectedInstances.length === 1"
            :value="SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_DIMENSIONS"
          >
            Scale down to specific dimensions
          </option>
        </b-form-select>
        <div v-if="selectedSize === SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_PERCENTAGE">
          <div class="my-2" />
          <b-form-input
            v-model="size.percentage"
            type="number"
          />
        </div>
        <div
          v-if="selectedSize === SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_DIMENSIONS"
          class="dimensions-container"
        >
          <div class="my-2" />
          <b-form-input
            v-model="size.dimensions.width"
            type="number"
            @change="handleWidthChange"
          />
          x
          <b-form-input
            v-model="size.dimensions.height"
            type="number"
            @change="handleHeightChange"
          />
        </div>
      </div>
    </div>
    
    <div
      v-if="selectedFormat === 'html'"
      class="form-row container-platforms"
    >
      <div class="col-md-2">
        <label>Platform</label>
      </div>
      <div class="col-md-10 form-group">
        <b-form-select
          v-model="selectedPlatform"
          class="form-control"
        >
          <option value="">
            None
          </option>
          <option value="ttd">
            The Trade Desk (TTD)
          </option>
        </b-form-select>
      </div>
    </div>
    <template #modal-footer>
      <b-button
        variant="primary"
        :disabled="isSaving"
        @click="onSave"
      >
        <div v-if="isSaving">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          Exporting...
        </div>
        <span v-else>Export</span>
      </b-button>
      <b-button
        variant="secondary"
        @click="onClose"
      >
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { exportTemplatesEventBroker, EVENTS } from '../../../group/modules/export-templates/event-broker';
import MultipleSelectJquery from '../../common/atoms/MultipleSelectJquery.vue';
import SelectFolder from '../../common/atoms/SelectFolder.vue';
import Multiselect from "vue-multiselect";
import {bus} from '../../../app'
import {SIZE_OPTIONS_TYPE} from "@frontend/constants/layout-export";
import layoutApi from "@frontend/services/api/layout";

const initSize = {
  percentage: 100,
  dimensions: {
    width: null,
    height: null,
  }
};


export default {
  name: 'ExportTemplatesModal',
  components: {
    MultipleSelectJquery,
    Multiselect,
    SelectFolder
  },
  data() {
    return {
      isSaving: false,
      formats: [
        {
          text: 'JPG',
          value: 'jpg'
        },
        {
          text: 'PNG',
          value: 'png'
        },
        {
          text: 'GIF',
          value: 'gif'
        },
        {
          text: 'HTML',
          value: 'html'
        },
        {
          text: 'PDF',
          value: 'pdf'
        },
        {
          text: 'PPTX',
          value: 'pptx'
        },
        {
          text: 'Feed',
          value: 'feed'
        },
        {
          text: 'PSD',
          value: 'psd'
        },
        {
          text: 'TIFF',
          value: 'tiff'
        },
        {
          text: 'JPG from PSD',
          value: 'jpg_from_psd'
        },
        {
          text: 'PSD and JPG',
          value: 'psd_and_jpg'
        },
      ],
      dpi: 72,
      selectedDpi: 72,
      selectedFormat: 'jpg',
      selectedPlatform: '',
      selectedSize: SIZE_OPTIONS_TYPE.KEEP_ORIGINAL,
      size: {...initSize},
      templateDimensions: {
        width: null,
        height: null,
      },
      isTemplatesLoading: false,
      templateAspectRatio: 1,
      selectedInstances: [],
      selectedGroupInstances: [],
      selectedSingleInstances: [],
      selectedFolder: null,
      instances: [],
      groupsInstances: [],
      singleInstances: [],
      folders: [],
      selectAllText : 'Select All',
      createPageForEachTemplate: true,
      createOneFilePerGroup: true,
      getDirectLinktoFile: false,
      downloadInBackground: true,
      singleInstanceId: null,
      groupInstancesMap: [],
      pptxOutputTypes: [
        {
          text: 'PPTX only',
          value: false
        },
        {
          text: 'PPTX and fonts in zip file',
          value: true
        }
      ],
      pptxIncludeFonts: false
    };
  },
  computed:{
    SIZE_OPTIONS_TYPE() {
      return SIZE_OPTIONS_TYPE
    },
      instancesOption() {
          const selectAllOption = {instance_id: -1, name: this.selectAllText};
          return [selectAllOption, ...this.instances];
      },
  },
  watch : {
      selectedFormat() {
        this.selectedSize = SIZE_OPTIONS_TYPE.KEEP_ORIGINAL;
        this.size = {...initSize};
      },
      selectedInstances(newSelectedInstances) {
          const allSelected = newSelectedInstances.some(option => option.instance_id === -1);
          if (allSelected) {
              this.toggleSelectAllText();
          }

          if (!newSelectedInstances.length ||
            (this.selectedSize === SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_DIMENSIONS &&
              (newSelectedInstances.length > 1 || newSelectedInstances.length === 0)
            )) {
            this.selectedSize = SIZE_OPTIONS_TYPE.KEEP_ORIGINAL;

            this.templateDimensions = {
              width: null,
              height: null,
            }
            
            this.size.dimensions = {
              width: null,
              height: null
            }
          } else {
            const preview = previews.find(preview => preview.id === newSelectedInstances[0].instance_id);

            if (preview) {
              this.templateDimensions = {
                width: preview.template.width,
                height: preview.template.height
              };

              this.templateAspectRatio = preview.template.width / preview.template.height;
            }
          }
      },
    selectedSize() {
        this.size.percentage = 100;
        
        if (this.selectedInstances.length === 1) {
          this.size.dimensions = {
            width: this.templateDimensions.width,
            height: this.templateDimensions.height
          }
        } else {
          this.size.dimensions = {
            width: null,
            height: null
          }
        }
    },
  },
  mounted() {
    exportTemplatesEventBroker.on(EVENTS.INIT, this.init.bind(this));
    exportTemplatesEventBroker.on(EVENTS.SHOW, this.onShow.bind(this));
    exportTemplatesEventBroker.on(EVENTS.CLOSE, this.onClose.bind(this));
    exportTemplatesEventBroker.on(EVENTS.FINISH, this.onFinish.bind(this));
    bus.$on('singleInstanceId', (singleInstanceId) => {
      if(singleInstanceId) {
        this.selectedInstances = this.instancesOption.filter(option => option.instance_id == singleInstanceId); 
      }
    });
  },
  methods: {
    async init({ layoutId, groupsTemplates, folders }) {
      this.isTemplatesLoading = true;

      try {
        const { data: templates } = await layoutApi.getLayoutTemplates(layoutId, { simplified: true });
        this.instances = templates.map(template => ({ instance_id: template.instance_id, name: template.name }));
      } catch (error) {
        console.error(error);
      } finally {
        this.isTemplatesLoading = false;
      }

      if (groupsTemplates.length) {
        this.groupsInstances = groupsTemplates.map((group, groupIndex) => ({
          type: 'optgroup',
          label: `Group ${groupIndex + 1}`,
          children: this.instances
            .filter(instance => group.instancesId.includes(instance.instance_id))
            .map(instance => ({
              text: instance.name,
              value: instance.instance_id,
              classes: 'export-pdf-groups-dropdown__option'
            }))
        }));

        this.groupInstancesMap = groupsTemplates.map((group, groupIndex) => ({
          group: groupIndex,
          instances: [...group.instancesId]
        }));
      }

      this.singleInstances = this.instances.filter(instance => {
        return !groupsTemplates.find((group) => {
          return group.instancesId.includes(instance.instance_id);
        })
      }).map(instance => {
        return {
          value: instance.instance_id,
          text: instance.name
        }
      });
      this.folders = folders;
    },
    onShow() {
      this.$bvModal.show('export-templates-modal');
    },
    onClose() {
      this.isSaving = false;
      this.$bvModal.hide('export-templates-modal');
    },
    onFinish(){
      this.isSaving = false;
    },
    onSave() {

      if (this.selectedFormat === 'pdf' && (!this.selectedGroupInstances.length && !this.selectedSingleInstances.length)) {
        window.toastr.error("Please select at least one group or template from the list");
        return;
      }

      if (this.selectedFormat === 'gif' && (!this.selectedGroupInstances.length && !this.selectedSingleInstances.length)) {
        window.toastr.error("Please select at least one group from the list");
        return;
      }

      if (this.selectedFormat !== 'gif' && this.selectedFormat !== 'pdf' && !this.selectedInstances.length){
        window.toastr.error("Please select at least one template from the list");
        return;
      }

      this.isSaving = true;

      exportTemplatesEventBroker.fire(EVENTS.EXPORT, {
        exportFormat: this.selectedFormat,
        selectedInstances: this.selectedInstances,
        selectedGroupInstances: this.getSelectedTemplateGroups(),
        selectedSingleInstances: this.selectedSingleInstances,
        selectedHTMLPlatform: this.selectedPlatform,
        dpi: this.dpi,
        createPageForEachTemplate: this.createPageForEachTemplate,
        getDirectLinktoFile: this.getDirectLinktoFile,
        selectedFolder: this.selectedFolder,
        createOneFilePerGroup: this.createOneFilePerGroup,
        downloadInBackground: this.downloadInBackground,
        sizeOptions: {
          type: this.selectedSize,
          scalePercentage: this.size.percentage,
          dimensions: this.size.dimensions,
        },
        pptxIncludeFonts: this.pptxIncludeFonts
      })
    },
    selectAllOptions(selected) {
        if (selected) {
            this.selectedInstances = this.instances.map(instance => instance.instance_id);
        } else {
            this.selectedInstances = [];
        }
    },
    toggleSelectAllText() {
        if (this.selectAllText === 'Select All') {
            this.selectedInstances = this.instancesOption.filter(option => option.instance_id !== -1);
            this.selectAllText = 'Unselect All';
        } else {
            this.selectedInstances = [];
            this.selectAllText = 'Select All';
        }
    },
    handleSelection(selectedFolderId) {
      this.selectedFolder = selectedFolderId;
    },
    handleWidthChange(newWidth) {
      this.size.dimensions.width = +newWidth;
      this.size.dimensions.height = Math.round(newWidth / this.templateAspectRatio);
    },
    handleHeightChange(newHeight) {
      this.size.dimensions.height = +newHeight;
      this.size.dimensions.width = Math.round(newHeight * this.templateAspectRatio);
    },
    getSelectedTemplateGroups() {
      const selectedGroups = new Set();
      
      this.selectedGroupInstances.forEach(instance => {
        const group = this.groupInstancesMap.find(groupInstance => groupInstance.instances.includes(instance));

        if (group) {
          selectedGroups.add(group.group);
        }
      });
      
      return Array.from(selectedGroups);
    }
  }
}
</script>
<style lang="scss">
@media (min-width: 576px) {
    #export-templates-modal .modal-dialog {
        width: 900px !important;
        max-width: 900px !important;
    }
}
.dimensions-container {
  input {
    display: inline-block;
    width: 30%;
  }
}

.container-downloadable-templates .multiselect__tags {
    max-height: 135px;
    overflow: scroll;
}

.export-pdf-groups-dropdown {
  &__option {
    pointer-events: none;
    
    input {
      display: none;
    }
  }
}
</style>
