<template>
  <div 
    v-click-outside="onSearchKeyBlur"
    class="product-images-input"
  >
    <b-form-input
      id="multipleValues"
      v-model="searchInputValue"
      placeholder="Product images"
      @input="handleInputChange"
      @focus="expandDropdown"
    />
    <b-button
      v-if="searchInputValue"
      variant="light"
      class="clear-product-input-btn"
      @click="$bvModal.show('modal-warning-product-image')"
    >
      <b-icon
        icon="x-circle"
        class="icon-clear-input"
      />
    </b-button>

    <div
      v-if="dropdownIsExpanded"
      id="file_ids_dropdown"
    >
      <div
        v-if="showEmptyRequest()"
        class="product-message"
      >
        Nothing was found
      </div>
      <div
        v-else
        class="image-grid-responsive"
      >
        <div class="grid">
          <div
            v-if="loading"
            class="product-message"
          >
            Loading images from database...
          </div>
          <template v-else>
            <div
              v-for="item in pagination.items"
              :key="item.id"
              class="product-grid-item"
              @click="$emit('dropdown-item-click', item)"
            >
              <div
                class="product-grid-item-controls"
                :class="{ 'is-check': getProductsIds.includes(item.id) }"
              >
                <input
                  type="checkbox"
                  class="select-check"
                  :checked="getProductsIds.includes(item.id)"
                >
              </div>
              <img
                :src="`/share?file=${item.thumbnail}`"
                loading="lazy"
              >
              <p style="word-break: break-all;">
                {{ item.name }}
              </p>
            </div>
          </template>
        </div>
      </div>
      <div class="image-grid-pagination">
        <VuePagination
          :pagination.sync="pagination"
          class-name="folders-library-pagination"
          @paginate="search()"
        />
      </div>
    </div>

    <b-modal
      id="modal-warning-product-image"
      hide-backdrop
      content-class="shadow"
      title=""
      @ok="clearInputValue"
    >
      <p class="my-2">
        Your changes will be lost! Are you sure you want to clear the workspace?
      </p>
    </b-modal>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import VuePagination from "@frontend/components/common/PaginationComponent.vue";

export default {
  name: 'ProductImagesDropdown',
  
  components: { VuePagination },
  
  directives: {
    ClickOutside
  },
  
  props: {
    selectedProducts: {
      type: Array,
      default: () => []
    }
  },
  
  data: () => ({
    dropdownIsExpanded: false,
    searchKey: '',
    pageSize: 10,
    pagination: {
      items: [],
      totalCount: 0,
      current_page: '1',
      from: 1,
      last_page: 1,
    },
    loading: false,
  }),

  computed: {
    searchInputValue: {
      get() {
        return this.selectedProducts.map(item => item.name).join(' ');
      },
      set(value) {
        this.setSearchKey(value)
      }
    },

    getProductsIds() {
      return this.selectedProducts.map(({ id }) => id);
    },
  },

  watch: {
    selectedProducts() {
      this.setSearchKey();
    }
  },

  methods: {
    handleInputChange: _.debounce(function () {
      this.setSearchKey();
      this.search();
    }, 500),

    expandDropdown() {
      this.dropdownIsExpanded = true;

      if (!this.pagination.items.length || this.searchInputValue.length) {
        this.search();
      }
    },

    onSearchKeyBlur() {
      this.dropdownIsExpanded = false;
    },

    clearInputValue() {
      this.pagination.items = [];
      this.searchKey = '';
      this.$emit('clear-input-value');
    },

    setSearchKey() {
      this.searchKey = this.normalizeInputValue();
    },

    normalizeInputValue() {
      return this.searchInputValue &&
        this.searchInputValue.split(/[, ]+/).map((v) => v.trim()).filter(v => v.length).join(',');
    },

    async search() {
      this.loading = true;
      const url = `
      /file/file_list?searchKey=${this.searchKey}
      &pageSize=${this.pageSize}
      &pageNumber=${this.pagination.current_page}`;
      const { data } = await axios.get(url);
      this.pagination = data;
      this.loading = false;
    },

    showEmptyRequest() {
      return !this.pagination.items.length && !this.loading
    }
  }
}
</script>

<style lang="scss" scoped>
  .product-images-input {
    position: relative;

    .clear-product-input-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      background: transparent;
      border: none;

      &:hover {
        background-color: transparent !important;
        border: none;
      }

      &:disabled {
        border: none;
      }

      &:focus {
        outline: none;
        box-shadow: none;
        border-color: transparent !important;
        background-color: transparent !important;
      }

      &:active {
        box-shadow: none !important;
      }
    }

    #file_ids_dropdown {
      top: 0;
      right: 0;
      height: 470px !important;
    }

    .product-message {
      position: absolute;
      left: 470px;
      top: 200px;
    }
  }
</style>
