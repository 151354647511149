<template>
  <div class="c-top-bar">
    <div class="c-top-bar__search_container d-flex  flex-grow-1 align-items-center">
      <div class="c-top-bar__search">
        <svg
          width="13"
          height="13"
          class="c-top-bar__search--icon"
        >
          <use href="/img/icons/sprite.svg#search-big" />
        </svg>
        <input
          v-model="searchInput"
          :disabled="!isTopBarSwitchersEnabled"
          type="text"
          placeholder="Search"
          class="c-top-bar__search--input"
          @keyup="debouncedSearchHandler"
        >
      </div>
      <b-btn
        v-if="isGoToTopAvailable"
        class="c-top-bar__reset-button c-top-bar__settings-button c-top-bar__search--button ml-2"
        @click="goBack"
      >
        <i class="cil-arrow-thick-from-bottom" />
      </b-btn>
    </div>
    <div class="d-flex align-items-center">
      <div class="d-flex justify-content-end align-items-center">
        <b-form-checkbox
          v-model="isFolderViewEnabledLocal"
          :disabled="!isTopBarSwitchersEnabled"
          switch
          @click.stop
        >
          Show folders
        </b-form-checkbox>
      </div>
    </div>
    <div class="d-flex justify-content-end align-items-center">
      <b-form-checkbox
        v-model="showOnlySelectedLocal"
        :disabled="!isTopBarSwitchersEnabled || !isAnyPicked"
        switch
        @click.stop
      >
        Show selected
      </b-form-checkbox>
    </div>
    <div v-if="!isShowAllFromCanvas">
      <ButtonPrimary
        variant="primary"
        @click="handleShowAll"
      >
        Show all
      </ButtonPrimary>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers, mapGetters} from 'vuex';
import {MUTATIONS} from "../../../store/file-browser-product-selector";
import ButtonPrimary from "@frontend/components/common/atoms/ButtonPrimary.vue";
import {debounce} from "lodash";

const { mapActions, mapMutations } = createNamespacedHelpers('fileBrowserProductSelector')

export default {
  name: "TopBar",
  components: {ButtonPrimary},
  data: () => ({
    searchInput: '',
    isFolderViewEnabledLocal: false,
    showOnlySelectedLocal: false,
    isReindexing: false,
    isGenerating: false,
    isRegenerating: false,
  }),
  computed: {
    ...mapGetters('fileBrowserProductSelector', [
      'isAnySelected',
      'isAnyPicked',
      'items',
      'selected',
      'isAnySelected',
      'selectedItems',
      'currentPage',
      'searchQuery',
      'currentFolderId',
      'currentFolderName',
      'isFolderViewEnabled',
      'showOnlySelected',
      'isTopBarSwitchersEnabled',
      'isShowAllFromCanvas'
    ]),
    ...mapGetters('layout', [
      'selectedInstance',
    ]),
    isGoToTopAvailable() {
      return false;
    },
  },
  watch: {
    isFolderViewEnabled(newValue) {
      if (newValue !== this.isFolderViewEnabledLocal) {
        this.isFolderViewEnabledLocal = newValue;
      }
    },
    showOnlySelected(newValue) {
      if (!newValue && this.searchInput === '') {
        this.setSearchQuery('');
      }
      
      if (newValue !== this.showOnlySelectedLocal) {
        this.showOnlySelectedLocal = newValue;
      }
    },
    isFolderViewEnabledLocal(newValue) {
      this.setIsFolderViewEnabled(newValue);
      this.fetchData();
    },
    showOnlySelectedLocal(newValue) {
      this.setShowOnlySelected(newValue);
      this.fetchData();
    }
  },
  mounted() {
    this.isFolderViewEnabledLocal = this.isFolderViewEnabled;
    this.showOnlySelectedLocal = this.showOnlySelected;
  },
  methods: {
    ...mapActions([
      'fetchData',
      'selectAll',
      'setCurrentPage',
      'setSearchQuery',
      'setIsFolderViewEnabled',
      'setShowOnlySelected',
    ]),
    ...mapMutations([MUTATIONS.CLEAR_SELECTION]),
    goBack() {
      this.setCurrentPage(1);
      this.fetchData();
    },
    searchHandler(event) {
      this.setSearchQuery(event.target.value);
      this.fetchData();
    },
    debouncedSearchHandler: debounce(function (event) {
      this.searchHandler(event);
    }, 500),
    handleShowAll() {
      this.selectedInstance.canvas.fire('add:product-images', {
        instanceId: this.selectedInstance.id,
        isNeedAddProductDimensionsField: false,
      })
      document.querySelector('#addProductImages').click();
    }
  }
}
</script>

<style scoped lang="scss">
@media (max-width: 1265px) {
    .c-top-bar {
        flex-direction: column;

        &__search_container {
            width: 100%;
        }
    }
}

.c-top-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  padding: 0 50px 0 50px;
  margin-bottom: 20px;

  ::v-deep &__reset-button {
    padding: 0;
    border: unset !important;
    background-color: unset;

    &:hover, &:active {
      background-color: unset !important;
      color: #727272 !important;
    }

    &:focus, &:active:focus {
      box-shadow: unset !important;
    }
  }

  ::v-deep &__settings-button {
    width: 26px;
    height: 22px;
    color: #727272;

    .cil-arrow-thick-from-bottom {
      font-size: 22px;
    }

    .cil-input, .cil-cut {
      font-size: 23px;
    }
  }

  &__search {
    position: relative;
    width: 100%;

    &--icon {
      position: absolute;
      right: 15px;
      top: 11px;
    }

    &--input {
      font-family: "Nunito", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #464646;
      width: 100%;
      height: 35px;
      background: white;
      border: 1px solid #b1b1b1;
      border-radius: 12px;
      padding: 0 35px 0 8px;
      outline: 0;
    }

    &--button {
      height: 30px;
      width: 28px;
    }
  }

  &__clear--button {
    background: transparent;
    color: #727272;
    font-weight: 700;
    font-size: 15px;

    &:hover {
      opacity: 0.75;
    }
  }

  &__settings-container {
    width: 50px;
    height: 40px;
  }

  &__settings {
    color: #007964 !important;
    background-color: transparent !important;
    border-radius: 8px;
    margin: 0 5px;
    border: 1px solid #007964 !important;
    padding: 0.375rem 0.75rem;

    &:active, &:hover {
      color: #007964 !important;
    }

    i {
      width: unset;
    }
  }
}
</style>
