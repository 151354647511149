<template>
  <div>
    <p class="mb-2">
      Group:
    </p>
    <b-form-group
      label="Background"
      label-cols="3"
    >
      <div class="d-flex">
        <ColorPicker
          :value="target.fill"
          :themes="themes"
          @input="onChange('bgColor', $event)"
        />
        <b-button
          v-b-tooltip.hover
          variant="primary"
          class="mx-2"
          size="sm"
          title="Copy hex color"
          @click="copyToClipboard(target.fill)"
        >
          <i class="cil-copy" />
        </b-button>
      </div>
    </b-form-group>

    <b-form-group
      label="Stroke"
      label-cols="3"
    >
      <div class="d-flex">
        <ColorPicker
          :value="target.stroke"
          :themes="themes"
          @input="onChange('strokeColor', $event)"
        />
        <b-button
          v-b-tooltip.hover
          variant="primary"
          class="mx-2"
          size="sm"
          title="Copy hex color"
          @click="copyToClipboard(target.stroke)"
        >
          <i class="cil-copy" />
        </b-button>
        <b-button
          v-b-tooltip.hover
          variant="primary"
          size="sm"
          title="Use background color"
          @click="onChange('strokeColor', target.fill)"
        >
          <b-icon icon="back" />
        </b-button>
      </div>
    </b-form-group>

    <b-form-group
      label="Stroke width"
      :disabled="target.locked"
    >
      <b-input-group>
        <b-input
          :value="target.strokeWidth"
          :min="0"
          :max="maxStrokeWidth"
          type="range"
          @input="onChange('thickness', $event)"
        />
        <template #append>
          <b-input
            :value="target.strokeWidth"
            :min="0"
            :max="maxStrokeWidth"
            type="number"
            @input="onChange('thickness', $event)"
          />
        </template>
      </b-input-group>
    </b-form-group>

    <PositionAndSize
      :h="(target.height * target.scaleY)"
      :w="(target.width * target.scaleX)"
      :x="target.left"
      :y="target.top"
      @input="onChange"
    />
    <b-btn
      variant="primary"
      @click="onChange(isCustomGroup ? 'ungroup' : 'group', null)"
    >
      {{ isCustomGroup ? 'Ungroup' : 'Group' }}
    </b-btn>
    
    <ApplyToAllTextDropdown
      v-if="isTextObjects"
      :options="options"
      :target="target"
    />
    
    <ApplyToAllImageDropdown
      v-if="isProductImageObjects"
      class="mt-3"
      :target="target"
    />
  </div>
</template>

<script>
import PositionAndSize from "./components/PositionAndSize.vue";
import ColorPicker from './components/ColorPicker.vue'
import { editorControlEventBroker, EVENTS } from '@frontend/group/modules/editor-control-popup/event-broker'
import { copyToClipboard } from '../../../../services/helpers'
import ApplyToAllTextDropdown
  from '@frontend/components/modules/layouts-page/EditorControl/components/ApplyToAllTextDropdown.vue';
import ApplyToAllImageDropdown
  from '@frontend/components/modules/layouts-page/EditorControl/components/ApplyToAllImageDropdown.vue';
export default {
  components: {
    ApplyToAllImageDropdown,
    ApplyToAllTextDropdown,
    PositionAndSize,
    ColorPicker,
  },

  props: {
    target: {
      type: Object,
      default: () => {
      },
    },
    options: {
      type: Object,
      default: () => {
      },
    },
    themes: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    maxStrokeWidth: 100,
  }),

  computed:{
    isCustomGroup() {
      return this.target.type !== 'activeSelection'
    },

    isTextObjects() {
      return this.options.isTextObjects
    },

    isProductImageObjects() {
      return this.options.isProductImageObjects
    },
  },

  methods: {
    copyToClipboard,
    onChange: _.debounce(function (action, value) {
      editorControlEventBroker.fire(EVENTS.CUSTOM_GROUP_CHANGED, {
        action,
        value,
      })
    }, 15),
    applyToAll([action]) {
      this.onChange(action, action === 'bgColor' ? this.target.fill : target.stroke);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
