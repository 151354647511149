var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showNextTemplateButton || _vm.selectedInstanceIndex
        ? _c(
            "b-button-group",
            { staticClass: "my-2", attrs: { size: "sm" } },
            [
              _vm.showNextTemplateButton
                ? _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.moveInstanceSelection(1)
                        },
                      },
                    },
                    [
                      _c("b-icon", { attrs: { icon: "arrow-down" } }),
                      _vm._v("\n      Next template\n    "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedInstanceIndex
                ? _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.moveInstanceSelection(-1)
                        },
                      },
                    },
                    [
                      _vm._v("\n      Previous template\n      "),
                      _c("b-icon", { attrs: { icon: "arrow-up" } }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.textFields, function (field, index) {
        return _c(
          "b-form-group",
          {
            key: `${field.element_id}_${index}`,
            attrs: { label: field.name, "label-for": `field_${field.id}` },
          },
          [
            _c("b-input", {
              attrs: {
                id: `field_${field.id}`,
                value: _vm.selectedInstance.data[field.element_id],
              },
              on: {
                focus: function ($event) {
                  return _vm.setActiveObject(field)
                },
                input: function ($event) {
                  return _vm.updateField(field, $event)
                },
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm._l(_vm.multilineTextFields, function (field, index) {
        return _c(
          "b-form-group",
          {
            key: `${field.element_id}_${index}`,
            attrs: { label: field.name, "label-for": `field_${field.id}` },
          },
          [
            _c("b-textarea", {
              attrs: {
                id: `field_${field.id}`,
                value: _vm.selectedInstance.data[field.element_id],
                "max-rows": "8",
              },
              on: {
                focus: function ($event) {
                  return _vm.setActiveObject(field)
                },
                input: function ($event) {
                  return _vm.updateField(field, $event)
                },
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            }),
            _vm._v(" "),
            _vm.shouldShowThisField(field)
              ? _c(
                  "b-button",
                  {
                    staticClass: "mt-1 btn-sm w-100",
                    attrs: { variant: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.redraw(field)
                      },
                    },
                  },
                  [
                    _c("b-icon", { attrs: { icon: "align-center" } }),
                    _vm._v("\n      Align text to "),
                    _c("strong", [
                      _vm._v(_vm._s(JSON.parse(field.options).Option1)),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("b-form-group", { staticClass: "translation-form-group" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-column" },
          [
            _c("b-form-select", {
              staticClass: "flex-grow-1 mr-2",
              attrs: { options: _vm.languages },
              model: {
                value: _vm.translateTargetLanguage,
                callback: function ($$v) {
                  _vm.translateTargetLanguage = $$v
                },
                expression: "translateTargetLanguage",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "button-wrapper mt-3" },
              [
                _c(
                  "b-dropdown",
                  {
                    attrs: {
                      id: "translate-options-dropdown",
                      text: "Translate",
                      variant: "primary",
                    },
                  },
                  [
                    _c(
                      "b-list-group",
                      { attrs: { flush: "" } },
                      [
                        _c(
                          "b-list-group-item",
                          [
                            _c(
                              "b-overlay",
                              {
                                staticClass: "d-inline-block",
                                attrs: {
                                  show: _vm.loadingTranslate,
                                  rounded: "",
                                  opacity: "0.6",
                                  "spinner-small": "",
                                  "spinner-variant": "primary",
                                },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "flex-shrink-0",
                                    on: { click: _vm.translate },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Translate\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-list-group-item",
                          [
                            _c(
                              "b-overlay",
                              {
                                staticClass: "d-inline-block",
                                attrs: {
                                  show: _vm.loadingCopyAndTranslate,
                                  rounded: "",
                                  opacity: "0.6",
                                  "spinner-small": "",
                                  "spinner-variant": "primary",
                                },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "flex-shrink-0",
                                    on: { click: _vm.copyAndTranslate },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Copy Template & Translate\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-list-group-item",
                          [
                            _c(
                              "b-overlay",
                              {
                                staticClass: "d-inline-block",
                                attrs: {
                                  show: _vm.loadingCopyFieldsAndTranslate,
                                  rounded: "",
                                  opacity: "0.6",
                                  "spinner-small": "",
                                  "spinner-variant": "primary",
                                },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "flex-shrink-0",
                                    on: { click: _vm.copyFieldsAndTranslate },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Copy Fields & Translate\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("b-form-group", { attrs: { label: "Global Update" } }, [
        _c(
          "div",
          { staticClass: "d-flex align-items-center" },
          [
            _c(
              "b-form-checkbox",
              {
                staticClass: "mr-1",
                on: { change: _vm.onChangeApplyToAllGlobal },
                model: {
                  value: _vm.isApplyToAllGlobalUpdate,
                  callback: function ($$v) {
                    _vm.isApplyToAllGlobalUpdate = $$v
                  },
                  expression: "isApplyToAllGlobalUpdate",
                },
              },
              [_vm._v("\n        Apply to\n      ")]
            ),
            _vm._v(" "),
            _c("SelectTemplateDropdown", {
              attrs: {
                "local-storage-key": "selected-templates-global-update",
                "clear-local-storage": true,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }