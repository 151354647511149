import _ from 'lodash'
import {
    FIELD_TYPE_BACKGROND_THEME_IMAGE_MULTIPLE,
    FIELD_TYPE_OUTLINE,
    FIELD_TYPE_RECTANGLE
} from '../frontend/constants/type-fields-of-template'
import BackgroundThemeImageMultipleField from "./fields/BackgroundThemeImageMultipleField"
import OutlineFiled from './fields/OutlineField'
import TemplateField from "./fields/TemplateField"
import { RectangleTemplateField } from './fields/RectangleTemplateField'

export default class Template {
    id = null
    name = '';
    width = 0;
    height = 0;
    fields = []

    constructor(templateData) {
        this.id = templateData.id
        this.name = templateData.name;
        this.width = templateData.width;
        this.height = templateData.height;
        this._initFields(templateData.fields)
    }

    _initFields(fields = []) {
        this.fields = fields.map((field) => {
            switch(field.type) {
                case FIELD_TYPE_BACKGROND_THEME_IMAGE_MULTIPLE:
                    return new BackgroundThemeImageMultipleField(field);
                case FIELD_TYPE_OUTLINE:
                    return new OutlineFiled(field);
                case FIELD_TYPE_RECTANGLE:
                    return new RectangleTemplateField(field);
                default:
                    return new TemplateField(field);
            }
        })
    }

    getFieldByName(name) {
        return this.fields.find((field) => field.name === name);
    }

    getFieldById(id) {
        return this.fields.find((field) => field.getId() === id)
    }

    getGroupNamesByType(type) {
        return this.getFieldsByType(type).map((field) => field.options.groupName)
    }

    getFieldsByType(type) {
        return this.fields.filter((field) => field.getType() === type)
    }

    getFieldsByTypes(types = []) {
        return this.fields.filter((field) => types.includes(field.getType()));
    }
    
    getFirstFieldsByType(type) {
        return this.getFieldsByType(type).shift();
    }

    getTypeFieldById(fieldId) {
        const field = this.getFieldById(fieldId)

        return field ? field.type : null
    }

    getFirstFieldByNameAndType(name, type) {
        return this.fields.find((field) => field.name === name && field.type === type);
    }

    getIndexFieldById(fieldId) {
        const fieldType = this.getTypeFieldById(fieldId)
        const fields = this.getFieldsByType(fieldType)


        return fields.findIndex((field) => field.getId() === fieldId)
    }
}