import { fabric } from 'fabric';
import {
    ADDITIONAL_MULTI_LINE_TEXT_FIELD_PREFIX_ID, ADDITIONAL_TEXT_PREFIX_ID,
    MULTI_LINE_PREFIX_ID,
    TEXT_PREFIX_ID
} from "@frontend/constants/templates-field-prefixes";
import { FIELD_TYPE_PRODUCT_DIMENSIONS } from "@frontend/constants/type-fields-of-template";

export const fieldsShouldKeepSelected = [
    MULTI_LINE_PREFIX_ID,
    TEXT_PREFIX_ID,
    ADDITIONAL_MULTI_LINE_TEXT_FIELD_PREFIX_ID,
    ADDITIONAL_TEXT_PREFIX_ID
];

const closestTargets = [
    '.tab-content',
    '.actions-grid',
    '#layers-modal',
    '#selectBkImgModal',
    '.prevent-unselect'
];

const preventedFromUnselectFields = [
  FIELD_TYPE_PRODUCT_DIMENSIONS
]; 

export const canvasEditModeControl = {
    canvas: null,
    keepSelected: true,
    recentlyEditModeExited: false,
    selectObjectOnclickOutsideHandler: (e)=> {
        const canvasId = canvasEditModeControl.canvas.getElement().id;
        const targetCanvasId = e.target.parentNode?.childNodes[0]?.id;

        const isCanvasIdMatch = canvasId === targetCanvasId;
        const isClosestMatch = closestTargets.some(selector => e.target.closest(selector));
        
        if (isCanvasIdMatch || isClosestMatch) return;
        
        const activeObject = canvasEditModeControl.canvas.getActiveObject();
        const isFieldPreventedFromUnselect = preventedFromUnselectFields.some(field => activeObject?.fieldType === field);
        
        if (!activeObject || isFieldPreventedFromUnselect) return;
        
        if (activeObject.isEditing) {
            activeObject.exitEditing();
            canvasEditModeControl.canvas.setActiveObject(activeObject);
            canvasEditModeControl.canvas.renderAll();
            return;
        }
        
        canvasEditModeControl.canvas.discardActiveObject();
        canvasEditModeControl.canvas.renderAll();
    },
    setCurrentStateForClickOutsideModule(canvas){
        this.canvas = canvas;
    },
    setKeepSelected(isKeepSelected) {
        this.keepSelected = isKeepSelected;
    },
    setRecentlyEditModeExited(recentlyEditModeExited) {
        this.recentlyEditModeExited = recentlyEditModeExited;
    },
    get isRecentlyEditModeExited() {
        const isRecentlyEditModeExited = this.recentlyEditModeExited;
        this.recentlyEditModeExited = false;
        
        return isRecentlyEditModeExited;
    },
    get isKeepSelected() {
        const keepSelected = this.keepSelected;
        this.keepSelected = true;
        
        return keepSelected;
    }
}
