import LayoutFolderService from '../services/api/layout-folder'

export default {
  namespaced: true,

  state: () => ({
    selectedInstance: null,
    currentPagePreviews: [],
    folders: [],
    keepAspectRatio: false,
    target: null,
    layers: [],
  }),

  getters: {
    selectedInstance: state => state.selectedInstance,
    selectedInstanceIndex: state => _.findIndex(state.currentPagePreviews, ['id', state.selectedInstance.id]),
    selectedInstanceFieldsWithType: state => type => _.filter(state.selectedInstance.template.fields, ['type', type]),
    currentPagePreviews: state => state.currentPagePreviews,
    folders: state => state.folders,
    keepAspectRatio: state => state.keepAspectRatio,
    target: state => state.target,
    layers: state => state.layers,
  },

  mutations: {
    SET_SELECTED_INSTANCE (state, payload) {
      state.selectedInstance = payload
    },
    SET_CURRENT_PAGE_PREVIEWS (state, payload) {
      state.currentPagePreviews = payload
    },
    SET_FOLDERS (state, payload) {
      state.folders = payload
    },
    SET_KEEP_ASPECT_RATIO (state, payload) {
      state.keepAspectRatio = payload
    },
    SET_TARGET (state, payload) {
      state.target = payload
    },
    SET_LAYERS (state, payload) {
      state.layers = payload
    },
    ADD_FOLDER (state, payload) {
      state.folders.push(payload)
    },
  },

  actions: {
    async fetchFolders ({ commit }, params = {}) {
      const { data } = await LayoutFolderService.getFolders(params)
      commit('SET_FOLDERS', data.items)
    },
    async createFolder ({ commit }, payload) {
      const { data } = await LayoutFolderService.createFolder(payload.parent_id, payload.name)
      commit('ADD_FOLDER', data)
      return data
    },
    async checkFolderName ({ commit }, payload){
      const { data } = await LayoutFolderService.checkFolderName(payload)
      return data
    },
  }
}
